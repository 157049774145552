import styled, { CSSProperties } from 'styled-components';

export type FullScreenLoaderProps = {
    color?: CSSProperties['color'];
};

export const FullScreenLoader = styled.div<FullScreenLoaderProps>`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: ${({ theme, color }) => theme?.colors?.primary ?? color ?? 'white'};
`;
