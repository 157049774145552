import { RuntimeConfig } from '@versori/spa-server/client';

export const defaultConfig: RuntimeConfig = {
    sessionBaseUrl: process.env.SESSION_BASE_URL || 'http://127.0.0.1:4433',
    connectApiBaseUrl: process.env.CONNECT_API_BASE_URL || 'http://127.0.0.1:8900/v1',
    organisationsApiBaseUrl: process.env.ORGANISATIONS_API_BASE_URL || 'http://127.0.0.1:8081/v1alpha1',
    organisationsApiV1BaseUrl: process.env.ORGANISATIONS_API_V1_BASE_URL || 'http://127.0.0.1:8081/v1',
    switchboardAiBaseUrl: process.env.SWITCHBOARD_AI_BASE_URL || 'http://127.0.0.1:8000',
    switchboardBaseUrl: process.env.SWITCHBOARD_BASE_URL || 'http://127.0.0.1:8080/v1alpha1',
    stripeCheckoutUrl: process.env.STRIPE_CHECKOUT_URL || 'https://buy.stripe.com/test_4gw6qAeneeXdfLi4gh',
    stripePublicKey:
        process.env.STRIPE_PUBLIC_KEY ||
        'pk_test_51M2xkKAhP8nR3OC3lFLl1HX3PVT68bS0kgtWfpGov5DOaetLkBsBLJJ4rQqiBKYH6WAzxXGjMsplZtRYmMWuCCBQ00y5IOXyL9',
    boardsWebsocketBaseUrl: process.env.BOARDS_WEBSOCKET_BASE_URL || 'ws://127.0.0.1:8888',
    loginUrl: process.env.LOGIN_URL || 'http://127.0.0.1:3030/auth/login',
    registrationUrl: process.env.REGISTRATION_URL || 'http://127.0.0.1:3030/register',
    onboardingUrl: process.env.ONBOARDING_URL || 'http://127.0.0.1:3030/onboarding',
    recoveryUrl: process.env.RECOVERY_URL || 'http://127.0.0.1:3030/password-reset',
    hubsBaseUrl: process.env.HUBS_BASE_URL || 'http://127.0.0.1:8082/apis/hubs-sdk/v1',
    wsdlConverterUrl: process.env.WSDL_CONVERTER_URL || 'https://switchboard-wsdl-converter-z3wk6doaoq-ez.a.run.app',
    defaults: {
        appLogo: 'https://storage.googleapis.com/versori-assets/placeholder/switchboard/default-app-v2.svg',
        profileImage: 'https://storage.googleapis.com/versori-assets/placeholder/placeholder-profile.png',
    },
    versoriAiBaseUrl: process.env.VERSORI_AI_BASE_URL || 'http://localhost:8088/v1',
    versoriHubsBaseUrl: process.env.VERSORI_HUBS_BASE_URL || 'http://localhost:8889/v1',
    functionsBaseUrl: process.env.FUNCTIONS_API_BASE_URL || 'http://127.0.0.1:8089/v1',
    analyticsBaseUrl: process.env.ANALYTICS_BASE_URL || 'http://localhost:8950/v1',
};
