import { loadConfig, ValidateError } from '@versori/spa-server/client';
import { enableMapSet } from 'immer';
import { lazy, StrictMode, Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { FullScreenLoader } from './components/utilities/FullScreenLoader';
import { defaultConfig } from './config/default-config';
import 'core-js/actual/string';
import './fonts.css';

enableMapSet();

async function main() {
    await loadConfig(defaultConfig).catch((err) => {
        // eslint-disable-next-line no-console
        console.error('loadConfig() failed', err instanceof ValidateError ? err.messages : err.message);
        throw err;
    });

    const App = lazy(() => import(/* webpackChunkName: "app" */ './main/App'));

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const root = ReactDOM.createRoot(document.getElementById('root')!);
    root.render(
        <StrictMode>
            <Suspense fallback={<FullScreenLoader />}>
                <App />
            </Suspense>
        </StrictMode>
    );
}

if (process.env.API_JWT) {
    const { fetch: originalFetch } = window;

    window.fetch = async (...args) => {
        let [resource, config = {}] = args;

        if (
            resource.toString().startsWith(process.env.SWITCHBOARD_BASE_URL ?? '') ||
            resource.toString().startsWith(process.env.CONNECT_API_BASE_URL ?? '')
        ) {
            const { headers = {} } = config;

            if (!(headers as any)['X-Versori-Internal-Token']) {
                (headers as any)['X-Versori-Internal-Token'] = process.env.API_JWT;
            }

            return originalFetch(resource as URL, { ...config, headers });
        }

        const response = await originalFetch(resource as URL, config);

        return response;
    };
}

main().then();
